.HomePageContainer {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SessionImage {
  margin-top: -10px;
  height: 'auto';
  width: 600px;
  transform: none;
}

.HomePageImage {
  max-width: 100%;
  height: auto;
}

@media (max-width: 480px) {
  .HomePageImage {
    width: 100%;
  }

  .SessionImage {
    width: 100%;
  }
}
