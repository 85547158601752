.AppBar {
  background-color: #ffffff;
}

.HeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  overflow: hidden; /* Prevent overflow */
  box-sizing: border-box; /* Ensure padding is included in the total width */
}

.HeaderLeftContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0; /* Prevent shrinking */
}

.HeaderIcon {
  width: 160px;
  max-height: 60px;
  height: auto;
}

.HeaderRightContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-right: 0; /* Ensure no extra margin */
  padding-right: 0; /* Ensure no extra padding */
}

@media (max-width: 600px) {
  .HeaderContainer {
    padding: 8px;
  }

  .HeaderIcon {
    width: 40px;
    max-height: 40px;
    height: auto;
  }

  .HeaderRightContainer {
    margin-right: 0; /* Ensure no margin on the right */
  }
}
